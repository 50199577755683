import { Link } from "react-router-dom";
import SideNavData from "../data/navigation.json";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import {
  Box,
  Grid,
  useTheme,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";

export const Footer = () => {
  const theme = useTheme();
  console.log("test")
  const isMatch = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <footer>
      {isMatch ? (
        <></>
      ) : (
        <Container maxWidth="xl" sx={{ mb: 5, mt: 5 }}>
          <Grid container spacing={5}>
            <Grid item md={6}>
              <Link to="/">
                <img
                  style={{ height: "2.5rem", width: "auto" }}
                  src="/images/logo.jpeg"
                  alt="Logo"
                />
              </Link>
              <br />
              <br />
              <br />
              <Typography sx={{textAlign:"justify"}}>
                RailTel believes that their experience and expertise in handling and undertaking telecom and ICT projects, has led them to be selected for implementation of various mission-mode projects for the Government of India including rolling out the National Knowledge Network, Bharat Net (formerly, the National Optical Fiber Network) and USOF funded optical fiber based connectivity project in North East India.
              </Typography>
              <br />
              <br />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography>Follow us on :</Typography>
                &nbsp; &nbsp;


                  <Link to="https://www.facebook.com/RailTel">
                  <IconButton
                    aria-label="Facebook"
                    style={{ backgroundColor: "white" }}
                  
                  >
                    <Facebook />
                  </IconButton>
                </Link>
                &nbsp; &nbsp;


                <Link to="https://www.instagram.com/railtelcorporation?igsh=MTlvZDljaGRuNzBjeg==">
                <IconButton
                  style={{ backgroundColor: "white" }}
                  aria-label="Instagram"
                  
                  >
                  <Instagram />
                </IconButton>
                  </Link>
                &nbsp; &nbsp;
                <Link to="https://twitter.com/RailTel">

                <IconButton
                  style={{ backgroundColor: "white" }}
                  aria-label="Twitter"
                  
                  >
                  <Twitter />
                </IconButton>

                  </Link>



              </Box>
            </Grid>
            <Grid item md={3}>
              <Box sx={{ textAlign: "center" }}>
                <Typography>
                  <b>Company</b>
                </Typography>
                <br />
                <br />
                <Link to="/about">
                  <Typography sx={{ mb: 1 }}>About Us</Typography>
                </Link>
                <Link to="/contact">
                  <Typography sx={{ mb: 1 }}>Contact Us</Typography>
                </Link>
                {/* <Link to="/about-founder">
                  <Typography sx={{ mb: 1 }}>About Founder</Typography>
                </Link> */}
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box sx={{ textAlign: "center" }}>
                <Typography>
                  <b>Quick Links</b>
                </Typography>
                <br />
                <br />
                {SideNavData.map((e, index) => {
                  return (
                    <Link key={index} to={e.url}>
                      <Typography sx={{ mb: 1 }}>{e.name}</Typography>
                    </Link>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
      <hr />
      <Box sx={{ p: 2, textAlign: "center" }}>
        <Typography>
          <b>© 2024, RailTel Corporation of India Ltd.</b>
        </Typography>
      </Box>
    </footer>
  );
};
