import { Box, Typography } from "@mui/material";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";

export const NotFound = () => {
  return (
    <>
      <Header />
      <Box
        height="50vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h5">404 | Page Not Found</Typography>
      </Box>
      <Footer />
    </>
  );
};
