import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Container, Typography, Grid } from "@mui/material";

export const AboutFounder = () => {
  return (
    <>
      <Header />
      <Container maxWidth="lg">
        <br />
        <br />
        <Typography variant="h3" textAlign="center">
          <b>About the founder</b>
        </Typography>
        <br />
        <br />
        <Grid container spacing={5}>
          <Grid item md={4}>
            <img src="/images/founder.png" alt="Founder" />
          </Grid>
          <Grid item md={8}>
            <Typography>
              Actor, artist, art director and executive creative producer
              Avinash Sringeri Divakar is the founder and CEO of the creative
              hub Eternity Creative Studio,through which he dabbles and masters
              in a variety of creative ventures. From designing sets for films
              and ads, organising events and workshops, running restaurants and
              cafes to even constructing creative structures (including his own
              studio), there is no challenge in the creative field that Avinash
              will not take up and deliver. A USP on the work front is the
              ability to deliver to the T in record time.
            </Typography>
            <br />
            <Typography>
              He is the grandson of the late legendary big screen comedian Mr
              Narasimharaju. A graduate of fine arts from Chitra Kala Parishat,
              Bengaluru.
            </Typography>
            <br />
            <Typography>
              <b>As an actor</b>
            </Typography>
            <br />
            <Typography>
              As an actor, Avinash has played the lead role in about 8 Kannada
              films and has featured in a couple of ads, including the Nescafe
              Sunrise advertisement series over the years.
            </Typography>
            <br />
            <Typography>
              <b>As an artist</b>
            </Typography>
            <br />
            <Typography>
              Worked on murals and sculptures for individual clients in
              BengaluruA sculpture of Charlie Chaplin brought in international
              acclaimWorked as art director and execution professional at the
              Mysuru DasaraFounded art studio Geechu, a space for artists and
              their works -- closed now.
            </Typography>
            <br />
            <Typography>
              <b>As an art director for ads</b>
            </Typography>
            <br />
            <Typography>
              A gamut of his work as an art director features brands like
              Raymonds, Titan, Big Bazaar, the Indian Orthodontic Society,
              Fastrack, Parachute, MyFit Clothing, Myntra, Live Love Laugh
              Foundation, Team Indus, Black Stone, Aptex, Cult and CureFit.
            </Typography>
            <br />
            <Typography>
              <b>As an art director for films</b>
            </Typography>
            <br />
            <Typography>
              Avinash's achievements include winning the Karnataka Film State
              Award for his work as an art director for the Kannada film Last
              Bus (in which he also played a lead role).His current projects
              include - art director for Kannada films Chase, Mahira, Mataash
              and Brahmi.
            </Typography>
            <br />
            <Typography>
              <b>As an executive producerIn addition to art direction</b>
            </Typography>
            <br />
            <Typography>
              Avinash forayed into another aspect of filmmaking as executive
              producer with upcoming Kannada films lastbus Maatash and Brahmi.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};
