import { useState } from "react";
import style from "./style.module.css";
import { Link } from "react-router-dom";
import SideNavData from "../data/navigation.json";
import { Menu, Close, PersonOutline } from "@mui/icons-material";
import {
  Box,
  AppBar,
  Drawer,
  Button,
  Divider,
  Toolbar,
  useTheme,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";

export const Header = () => {
  const isLoggedIn = sessionStorage.getItem("isLoggedIn");
  const [active, setActive] = useState(false);

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <header style={{ marginBottom: "5rem" }}>
      <AppBar
        open={active}
        elevation={0}
        position="fixed"
        color="primary"
        sx={{ pt: 1, pb: 1 }}
      >
        <Toolbar>
          <IconButton
            sx={{ mr: 3 }}
            aria-label="Menu"
            onClick={() => setActive(!active)}
          >
            {active ? <Close color="secondary" /> : <Menu color="secondary" />}
          </IconButton>
          <Link to="/">
            <img
              style={{ height: "4rem", width: "auto" }}
              src="/images/logo.jpeg"
              alt="Logo"
            />
          </Link>
          {isMatch ? (
            <></>
          ) : (
            <>
              <Box sx={{ textAlign: "center", width: "100%", ml: 4, mr: 4 }}>
                <input
                  type="text"
                  placeholder="Search"
                  className={style.search_bar}
                />
              </Box>
              {isLoggedIn == "true" ? (
                <Link to="/profile">
                  <div className={style.profile_btn}>
                    <PersonOutline color="secondary" />
                    <Typography sx={{ ml: 2 }}>
                      <b>Admin</b>
                    </Typography>
                  </div>
                </Link>
              ) : (
                <Link to="/login">
                  <Button variant="contained" color="error">
                    <Typography>
                      <b>Login</b>
                    </Typography>
                  </Button>
                </Link>
              )}
            </>
          )}
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          zIndex: "2",
          width: "250px",
          position: "relative",
          "& .MuiDrawer-paper": {
            backgroundColor: "black",
            width: "250px",
            border: 0,
          },
        }}
        open={active}
        variant="persistent"
        anchor="left"
      >
        <Box>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Box>

        {isMatch ? (
          <>
            <Box textAlign="center">
              {isLoggedIn == "true" ? (
                <Link to="/profile">
                  <Box display="flex" ml={3}>
                    <PersonOutline color="secondary" />
                    &nbsp; &nbsp;
                    <Typography color="white">
                      <b>Admin</b>
                    </Typography>
                  </Box>
                </Link>
              ) : (
                <Link to="/login">
                  <Button variant="contained" color="error">
                    <Typography>
                      <b>Login</b>
                    </Typography>
                  </Button>
                </Link>
              )}
            </Box>

            <br />
            <br />
            <Divider color="white" />
            <br />
            <br />
          </>
        ) : (
          <></>
        )}

        <Box sx={{ pl: 4 }}>
          {SideNavData.map((e, index) => {
            return (
              <Box key={index}>
                <Link to={e.url}>
                  <Typography color="white">
                    <b>{e.name}</b>
                  </Typography>
                </Link>
                <br />
                <br />
              </Box>
            );
          })}
        </Box>
      </Drawer>
    </header>
  );
};
