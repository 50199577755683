import { Header } from "components/Header";
import { Footer } from "components/Footer";
import { Box, Typography } from "@mui/material";

export const Profile = () => {
  return (
    <>
      <Header />
      <Box
        height="50vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h5">Profile Goes Here</Typography>
      </Box>
      <Footer />
    </>
  );
};
