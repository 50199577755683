import { CheckAuth } from "./auth/CheckAuth";
import { LoginKeeper } from "./auth/LoginKeeper";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  About,
  Login,
  Player,
  SignUp,
  Contact,
  Profile,
  Category,
  NotFound,
  AboutFounder,
} from "./pages";

export const Router = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route index element={<Navigate to="category/home" replace />} />

      <Route path="about" element={<About />} />
      <Route path="contact" element={<Contact />} />
      <Route path="category/:name" element={<Category />} />
      <Route path="about-founder" element={<AboutFounder />} />

      <Route element={<CheckAuth />}>
        <Route path="profile" element={<Profile />} />
        <Route path="player/:videoId" element={<Player />} />
      </Route>

      <Route element={<LoginKeeper />}>
        <Route path="login" element={<Login />} />
        <Route path="sign-up" element={<SignUp />} />
      </Route>
    </Routes>
  );
};
