import { Typography } from "@mui/material";
import { Header } from "components/Header";
import { Footer } from "components/Footer";

export const SignUp = () => {
  return (
    <>
      <Header />
      <Typography>SignUp</Typography>
      <Footer />
    </>
  );
};
