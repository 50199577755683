import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Container, Typography, Grid } from "@mui/material";

export const About = () => {
  return (
    <>
      <Header />
      <Container maxWidth="lg">
        <br />
        <br />
        <Typography variant="h3" textAlign="center">
          <b>About RailTel</b>
        </Typography>
        <br />
        <br />
        <Typography>

          RailTel, a "Mini Ratna (Category-I)" Central Public Sector Enterprise is an ICT provider and one of the largest neutral telecom infrastructure providers in the country owning a Pan-India optic fiber network. The OFC network covers important towns & cities of the country and several rural areas.
          <br />
          <br />
          RailTel was incorporated on September 26, 2000 with the aim of modernizing the existing telecom system for train control, operation, and safety and to generate additional revenues by creating nationwide broadband and multimedia network, laying optical fiber cable using the right of way along railway tracks. Presently, the optic fiber network of RailTel covers over 61000+ route kilometers and covers 6108+ railway stations across India. Our citywide access across the country is 21000+ kms.
          <br />
          <br />

          RailTel’s various operations are certified for Tier-III (Design & Facility), ISO 27001:2013 Certified for Information Security Management System, ISO 20000:2018 Certified for Service Management System, ISO 9001:2015 Certified for Quality Management System, ISO 27017:2015 Certified for Cloud Security, ISO 27018:2019 Certified for Data Privacy in Cloud Service, ISO 27033 Certified for Network Security, CMMI Maturity Level-4 Certified for Process Improvement.
          <br />
          <br />
          RailTel has a strategic relationship with the Indian Railways and it undertakes a wide variety of projects including provision of mission critical connectivity services like IP based video surveillance system at stations, ‘e-Office’ services and implementing short haul connectivity between stations and long haul connectivity to support various organizations within the Indian Railways. RailTel also provide various passenger services including content on demand services and Wi-Fi across major railway stations in India.
        </Typography>
      </Container>
      <Footer />
    </>
  );
};
