import { Header } from "components/Header";
import { Footer } from "components/Footer";
import { Box, Typography, Grid } from "@mui/material";

export const Contact = () => {
  return (
    <>
      <Header />
      <Typography variant="h3" textAlign="center">
        <b>Contact Us</b>
      </Typography>


      <Box sx={{ px: 27 }}>

        <h1>

          RailTel Corporation of India Limited.
        </h1>
        <br />
        <div>
          (A Government of India Undertaking)
        </div>
        <br />
        <div>

          CIN: L64202DL2000GOI107905
        </div>
        <div>

          Registered and Corporate Office:- Plate-A, 6th Floor, Office Block Tower-2,
          <br />
          East Kidwai Nagar, New Delhi-110023
          <br />
          Tel.: +91 11 22900600
          <br />
          Fax: +91 11 22900699
        </div>

        <br />
        <div>

          Gurugram Office : 143, Institutional Area, Sector-44,
          <br />
          Gurugram - 122003, NCR (India)
          <br />
          Tel: +91 124 2714000
          <br />
          Fax: +91 124 4236084
          <br />
          Website : www[dot]railtelindia[dot]com
        </div>

        For any information on Products & Services , write to us : info[at]railtelindia[dot]com

      </Box>


      <Footer />
    </>
  );
};
