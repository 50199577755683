import { Header } from "components/Header";
import { Footer } from "components/Footer";
import { useEffect, useState } from "react";
import style from "assets/style.module.css";
import { Navigation, Autoplay } from "swiper";
import { PlayArrow } from "@mui/icons-material";
import { UISkeleton } from "components/Skeleton";
import { Link, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { CategoryData } from "middleware/category";
import {
  Box,
  Grid,
  Button,
  useTheme,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import 'swiper/swiper-bundle.css';

export const Category = () => {
  const { name } = useParams();

  const [data, setData] = useState({});

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const res = CategoryData(name);
    setData(res);

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [name]);

  const swiperParams = {
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    // modules={[Navigation, Autoplay]},
    // slidesPerView={isMatch ? 2 : 6},
    // navigation,
    // autoplay,

    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  if (Object.keys(data).length === 0) {
    return (
      <>
        <Header />
        <UISkeleton />
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />
      <section
        className={style.top_sec}
      // style={{ backgroundImage: `url(${data.topSec?.image})` }}
      >
        {/* <Container maxWidth="xl">
          <Grid container>
            <Grid item md={6}>
              <Box mt={isMatch ? 5 : 10}>
                <Typography variant="h2">
                  <b>{data.topSec?.name}</b>
                </Typography>
                <br />
                <Typography>
                  <b>Hindi || Action, Drama, Spy</b>
                </Typography>
                <br />
                <Typography>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Temporibus recusandae ipsa cupiditate quibusdam corporis!
                  Earum debitis accusantium architecto enim asperiores ullam
                  pariatur. Magni quaerat labore culpa assumenda praesentium
                  officia facere? 
                </Typography>
                <br />
                <br />
                <Link to={`/player/${data.topSec?.id}`}>
                  <Button variant="contained" color="error">
                    <PlayArrow />
                    &nbsp;&nbsp;&nbsp;
                    <b>Play Now</b>
                  </Button>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container> */}

        <Swiper
          //  {...swiperParams}
          modules={[Navigation, Autoplay]}
          // navigation
          autoplay


        >
          <SwiperSlide>
            <img
              src="/images/homeimg1.png"
              alt="Image 1"
              style={{ height: "100%" }}
            />
          </SwiperSlide>

          <SwiperSlide>
            <img
              src="/images/homeimg2.png"
              alt="Image 1"
              style={{ height: "100%" }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="/images/homeimg3.jpeg"
              alt="Image 1"
              style={{ height: "100%" }}
            />
          </SwiperSlide>
         
        </Swiper>

      </section>
      <br />
      <br />
      <br />
      <Container maxWidth="xl">
        <Typography variant="h5">Featured Shows</Typography>
        <br />
        <Grid container spacing={1}>
          {data.featured &&
            data.featured.map((e, index) => {
              return (
                <Grid key={index} item md={2} xs={4}>
                  <Link to={`/player/${e.id}`}>
                    <div className={style.item}>
                      <img loading="lazy" src={e.image} alt="Card" />
                    </div>
                  </Link>
                </Grid>
              );
            })}
        </Grid>
      </Container>
      <br />
      <br />
      <br />
      {data.sections &&
        data.sections.map((e, index) => {
          return (
            <Box key={index}>
              <Container maxWidth="xl">
                <Typography variant="h5">{e.label}</Typography>
                <br />
                <Swiper
                  modules={[Navigation, Autoplay]}
                  slidesPerView={isMatch ? 2 : 6}
                  navigation
                  autoplay
                >
                  {e.data.map((ele, inx) => {
                    return (
                      <SwiperSlide key={inx}>
                        <Link to={`/player/${ele.id}`}>
                          <Box sx={{ p: 1 }}>
                            <img loading="lazy" style={{ height: e.label === "Live Channels" ? "150px" : null }} src={ele.image} alt="Card" />
                          </Box>
                        </Link>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Container>
              <br />
              <br />
              <br />
            </Box>
          );
        })}
      <Footer />
    </>
  );
};
